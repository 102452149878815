<template>
  <div class="app-container">
    <search-header :query="query" :bankList="bankList" @search="searchChange" @refreshPage="refreshPage" />
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <CommonTable :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data">
      <template #bankSlot="{ scoped: row }">{{ getBank(row.bankCategoryId) }} </template>
      <template #updateTimeSlot="{ scoped: row }">{{ parseTime(row.updateTime) }} </template>
      <el-table-column fixed="right" label="操作" width="200px" align="center">
        <template slot-scope="{ row, $index }">
          <span v-if="!row.isEnabled">
            <color-text-btn
              v-p="['externaladmin:financialManagement:receivingAccountManagement:edit']"
              @click="onEdit(row)"
              >编辑</color-text-btn
            >
            <el-divider
              v-p="['externaladmin:financialManagement:receivingAccountManagement:edit']"
              direction="vertical"
            ></el-divider>
          </span>
          <el-popover
            v-p="['externaladmin:financialManagement:receivingAccountManagement:disable']"
            placement="top"
            :ref="`popover-${$index}`"
            width="200"
            trigger="click"
          >
            <slot name="tip">
              <p style="font-weight: 500; font-size: 16px">提示</p>
              <p v-if="row.isEnabled">您确定要禁用吗？</p>
              <p v-else>您确定要启用吗？</p>
            </slot>
            <div style="text-align: right">
              <el-button size="mini" @click="onCancel($index, row)">取消</el-button>
              <el-button
                :loading="row.loading"
                size="mini"
                type="primary"
                @click="onConfirm(row.isEnabled, row, $index)"
              >
                确定
              </el-button>
            </div>
            <template>
              <color-text-btn v-if="!row.isEnabled" :loading="row.loading" slot="reference">启用</color-text-btn>
              <color-text-btn v-else :loading="row.loading" slot="reference">禁用</color-text-btn>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    <edit-dialog
      type="edit"
      :sup_this="sup_this"
      :bankList="bankList"
      :form.sync="form"
      :visible.sync="visible"
      :bankID="bankID"
      @refreshPage="refreshPage"
    />
  </div>
</template>

<script>
import searchHeader from './module/header'
import { parseTime } from '@/utils'
import { initDataMixin } from '@/mixins'
import { accountCols as cols } from './module/cols'
import editDialog from './module/addOrEditDialog.vue'
import { getBankList } from '@/api/accountApi'
import { updateStatus } from '@/api/accountApi'

export default {
  components: { searchHeader, editDialog },
  mixins: [initDataMixin],
  data() {
    return {
      url: '/externaladmin/financeService/payeeBankAccount/list',
      mergeData: {
        orderItems: [
          {
            column: 'update_time',
            asc: false
          }
        ]
      },
      cols,
      visible: false,
      sup_this: this,
      query: {
        bankId: null,
        companyName: ''
      },
      form: {
        accountName: '',
        bankNumber: '',
        bankID: '',
        branchName: '',
        state: false
      },
      bankID: 1,
      finalData: [{}],
      bankList: []
    }
  },
  async mounted() {
    try {
      const { code, detail } = await getBankList()
      if (!$SUC(code)) {
        this.bankList = detail
      }
    } catch (error) {}
  },
  methods: {
    parseTime,
    getBank(id) {
      try {
        return this.bankList?.find((item) => item.id == id).itemName
      } catch (error) {}
    },
    setLoadingFalse(row, index) {
      row.loading = false
      this.$refs[`popover-${index}`].doClose()
    },
    async onConfirm(state, row, index) {
      this.$set(row, 'loading', true)
      const message = !state ? '启用成功' : '禁用成功'
      // const { description, isEnabled } = row
      try {
        //调用接口，如果code == 0则继续
        let { code } = await updateStatus({
          id: row.id,
          isEnabled: row.isEnabled == 0 ? 1 : 0
        })
        if ($SUC(code)) return
        this.setLoadingFalse(row, index)
        this.init()
        this.$message.success(`${message}`)
      } catch (error) {
        this.setLoadingFalse(row, index)
      }
    },
    onCancel(index, row) {
      this.setLoadingFalse(row, index)
      this.$message.warning('您取消了操作')
    },
    // onOperate(operate, data) {
    //   const { id } = data
    //   调用启用/禁用接口
    //   this.init()
    //   const message = operate == 'open' ? '启用成功' : '禁用成功'
    //   this.$message.success(message)
    // },
    // update() {
    //   this.form.accountName = enterpriseName
    //   this.form.bankNumber = bankCardNo
    //   this.form.bankID = bankOfDeposit
    //   this.form.branchName = bankBranchName
    //   this.form.state = accountEnable == 0 ? true : false
    // },
    initCallBack() {
      this.data.forEach((v) => {
        const key = v.configCode
        this.$set(this.finalData[0], key, v.configValue)
      })
    },
    onEdit(data) {
      const { bankAccountNo, bankCategoryId, bankName, companyName, isEnabled } = data
      this.$set(this.form, 'accountName', companyName)
      this.$set(this.form, 'bankNumber', bankAccountNo)
      this.$set(this.form, 'bankID', bankName)
      this.$set(this.form, 'branchName', bankCategoryId)
      let enable = isEnabled == 1 ? true : false
      this.$set(this.form, 'state', enable)
      this.bankID = data.id
      this.visible = true
    },
    refreshPage() {
      this.init()
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 30px;
  flex-direction: column;
  display: flex;
  .ready {
    padding: 3px;
    background: #fa9841;
    border-radius: 2px;
    color: white;
    font-size: 12px;
  }
}
</style>
